
.Input, .FakeInput {
	font-size: .60em;
	border-radius: 4px;
	background-color: var(--default-color);
	padding:.5em;
	text-transform: uppercase;
  user-select: none;
  text-align: center;
  transition: background-color .5s;
}
.Input {
  cursor: pointer;
}
.Input:not(:last-child), .FakeInput:not(:last-child) {
  margin-right: .3em;
}

.Input.locked, .FakeInput.locked {
	cursor: default;
	background-color: var(--correct-color);
}

.Input.incorrect, .FakeInput.incorrect {
	cursor: default;
	background-color: var(--incorrect-color);
}

.Input__letter {
	padding: 0em;
	border-bottom: solid 4px white;
	width: 1em;
	height: 1em;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-3-29 19:21:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation blink-2
 * ----------------------------------------
 */
@-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.Input.selected:not(.locked) .Input__letter {
	-webkit-animation: blink-2 1s infinite backwards;
	        animation: blink-2 1s infinite backwards;
}