.Puzzle {
	display: flex;
	flex-direction: row;
  	user-select: none;
}

.Puzzle[data-inputs="8"] .Puzzle__noun__inputs,
.Puzzle[data-inputs="8"] .Puzzle__adjective__inputs,
.Puzzle[data-inputs="8"] .Puzzle__noun__fake-inputs,
.Puzzle[data-inputs="8"] .Puzzle__adjective__fake-inputs {
	font-size: min(6.5vw, calc(min(800px, 100vw)*.065));
}
.Puzzle[data-inputs="9"] .Puzzle__noun__inputs,
.Puzzle[data-inputs="9"] .Puzzle__adjective__inputs,
.Puzzle[data-inputs="9"] .Puzzle__noun__fake-inputs,
.Puzzle[data-inputs="9"] .Puzzle__adjective__fake-inputs {
	font-size: min(5.8vw, calc(min(800px, 100vw)*.058));
}
.Puzzle[data-inputs="10"] .Puzzle__noun__inputs,
.Puzzle[data-inputs="10"] .Puzzle__adjective__inputs,
.Puzzle[data-inputs="10"] .Puzzle__noun__fake-inputs,
.Puzzle[data-inputs="10"] .Puzzle__adjective__fake-inputs {
	font-size: min(5.5vw, calc(min(800px, 100vw)*.055));
}
.Puzzle[data-inputs="11"] .Puzzle__noun__inputs,
.Puzzle[data-inputs="11"] .Puzzle__adjective__inputs,
.Puzzle[data-inputs="11"] .Puzzle__noun__fake-inputs,
.Puzzle[data-inputs="11"] .Puzzle__adjective__fake-inputs {
	font-size: min(5.2vw, calc(min(800px, 100vw)*.052));
}
.Puzzle[data-inputs="12"] .Puzzle__noun__inputs,
.Puzzle[data-inputs="12"] .Puzzle__adjective__inputs,
.Puzzle[data-inputs="12"] .Puzzle__noun__fake-inputs,
.Puzzle[data-inputs="12"] .Puzzle__adjective__fake-inputs {
	font-size: min(4.75vw, calc(min(800px, 100vw)*.0475));
}

.Puzzle__adjective {
	display: flex;
	flex-direction: column;
	flex: 0 0;
	margin-right:1.5em;

}

.Puzzle__adjective__header,.Puzzle__noun__header {
	font-size:4vmin;
}

.Puzzle__adjective__header::after, .Puzzle__noun__header::after{
	content: '';
	border-top: solid white 4px;
	border-left: solid white 3px;
	border-right: solid white 3px;
	height: 10px;
	display:block;
	margin-bottom:1.5vh;
}
.Puzzle__adjective__inputs, .Puzzle__noun__inputs,
.Puzzle__adjective__fake-inputs, .Puzzle__noun__fake-inputs {
	display: flex;
	flex-direction: row;
}

.Puzzle__adjective__fake-inputs, .Puzzle__noun__fake-inputs {
  transform-origin: bottom;
  transform: rotateX(-80deg) translateY(2em);
/*  transform: rotate3d(1,0,0,80deg) translate;*/
	display:flex;
	position:absolute;
}


.Puzzle__noun {
	display: flex;
	flex-direction: column;
	flex: 0 0;
}