.AboutScreen {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	pointer-events: none;
	text-align: center;
}

.coverall {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: black;
	transition: opacity .5s;
	pointer-events: default;
}

.AboutScreen.hidden .coverall {
	opacity: 0%;
	z-index: 0;
}
.AboutScreen:not(.hidden) .coverall {
	opacity: 60%;
	z-index: 20;
	pointer-events: auto;
}

.AboutModal {
	margin: 1.75rem auto;
	position: relative;
	color: var(--font-color);
	background-color: var(--main-bg-color);
	padding: 2em;
	max-width:30em;
	width: 80vw;
	transition: transform .5s;
	pointer-events: auto;
	display:flex;
	flex-direction:column;
	max-height: 80vh;	
}

html.iossafari .AboutModal {
	max-height: 70vh;
}

.AboutScreen.hidden .AboutModal {
	-webkit-transform: translateY(-200vh);
          transform: translateY(-200vh);
	z-index: 0;

}

.AboutScreen:not(.hidden) .AboutModal {
	-webkit-transform: translateY(0);
          transform: translateY(0);
	z-index: 21;

}

.AboutModal__header {
	font-size:2em;
	display:grid;
	grid-template-columns: 1fr auto 1fr;
	grid-template-areas: "logo title .";
	white-space: nowrap;
	margin-bottom:.5em;
}

.AboutModal__header .header-logo {
  grid-area: logo;
}

.AboutModal__close {
	position:absolute;
	right:2em;
	top: 0;
	cursor: pointer;
	user-select: none;
}
.AboutModal__close::before {
	content: "x";
	position: absolute;
	color: var(--disabled-color);
	font-size:2em;
	font-weight:bold;
}

.AboutModal__faq-section {
	text-align: left;
	margin-bottom: 1em;
	font-size: min(1em, 1.75vh);
}

.AboutModal__faq-section__header {
	width:100%;
	font-size:1.5em;
	border-bottom: solid 1px var(--disabled-color);
}

.how-to-play__or {
	margin-left: 1em;
	margin-top:.5em;
	margin-bottom: .5em;
}

.AboutModal__faq-section__text__bullets li {
	margin-bottom: .5em;
}

.AboutModal__body {
	overflow: auto;
}