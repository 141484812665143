.DataModalHolder {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	pointer-events: none;
	text-align: center;
	display: flex;
}

.DataModal {
	margin: auto auto;
	position: relative;
	color: var(--font-color);
	background-color: var(--main-bg-color);
	padding: 1em;
	max-width:30em;
	width: 80vw;
	transition: transform .3s, opacity .2s;
	pointer-events: auto;
	display:flex;
	flex-direction:column;
}

.DataModalHolder.hidden .DataModal {
    transform: scale(0%);
    opacity: 0%;
	z-index: 0;
}

.DataModalHolder:not(.hidden) .DataModal {
    transform: scale(100%);
    opacity: 100%;
	z-index: 21;

}
.DataModal__header {
	font-weight: bold;
	margin-bottom:1em;
	padding-bottom:1em;
	border-bottom: solid 1px var(--disabled-color);
	margin-right:-1em;
	margin-left:-1em;
}

.DataModal__close {
	position:absolute;
	right:2em;
	top: 0;
	cursor: pointer;
	user-select: none;
}
.DataModal__close::before {
	content: "x";
	position: absolute;
	color: var(--disabled-color);
	font-size:2em;
	font-weight:bold;
}

.DataModalHolder.hidden .coverall {
	opacity: 0%;
	z-index: 0;
}
.DataModalHolder:not(.hidden) .coverall {
	opacity: 60%;
	z-index: 20;
	pointer-events: auto;
}