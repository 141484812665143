.Keyboard {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: min(4.5vmin, calc(min(800px, 100vw)*.045));
}
.Keyboard__Row {
	display:flex;
	flex-direction: row;
	align-items: center;
	margin-bottom:.3em;
}

.Keyboard__Row__Key {
	border-radius: 4px;
	background-color: var(--default-color);
	padding:.5em;
	margin-right: .3em;
	text-transform: uppercase;
	cursor: pointer;
}

.Keyboard__Row__Key {
	user-select: none;
	text-align: center;
}

.Keyboard__Row__Key__letter {
	padding: 0;
	font-size: .8em;
}

.Keyboard__Row__Key.solver{
	background-color: var(--button-color--info);
}

.Keyboard__Row__Key.solver .Keyboard__Row__Key__letter{
	height:1em;
}

.Keyboard__Row__Key:not(.solver) .Keyboard__Row__Key__letter {
	padding: 0;
	width: 1em;
	height: 1em;
	font-size: .8em;
}

.Keyboard__Row__Key.correct {
	background-color: var(--correct-color);
	cursor: default;
}


.Keyboard__Row__Key.incorrect {
	background-color: var(--incorrect-color);
	cursor: default;
}

.Keyboard__Row__Key.disabled {
	background-color: var(--disabled-color);
	cursor: default;
}