:root {
  --dark-main-bg-color: #282c34;
  --dark-correct-color: green;
  --dark-default-color: grey;
  --dark-incorrect-color: red;
  --dark-warning-color: yellow;
  --dark-font-color: white;
  --dark-progress-color: #303137;
  --dark-disabled-color: #444444;
  --dark-button-color--default: buttonface;
  --dark-button-color--info: blue;
  --dark-button-color--warning: var(--dark-warning-color);
  --dark-button-color--danger: var(--dark-incorrect-color);
  --dark-button-color--ok: var(--dark-correct-color);
  --dark-link-color: rgb(144, 202, 249);
  --dark-link-color--visited: var(--dark-link-color);
}
.theme--dark {
  --main-bg-color: var(--dark-main-bg-color);
  --correct-color: var(--dark-correct-color);
  --default-color: var(--dark-default-color);
  --incorrect-color: var(--dark-incorrect-color);
  --warning-color: var(--dark-warning-color);
  --font-color: var(--dark-font-color);
  --progress-color: var(--dark-progress-color);
  --disabled-color: var(--dark-disabled-color);
  --button-color--info: var(--dark-button-color--default);
  --button-color--info: var(--dark-button-color--info);
  --button-color--warning: var(--dark-button-color--warning);
  --button-color--danger: var(--dark-button-color--danger);
  --button-color--ok: var(--dark-button-color--ok);
  --link-color: var(--dark-link-color);
  --link-color--visited: var(--dark-link-color--visited);
}

html.iossafari, html.iossafari body, html.iossafari #root {
  height: 100%;
}

body, html, #root {
  height: stretch;
}


.App {
  display: grid;
  grid-template-columns: [left] 1fr [center-left] min(90vw, 800px) [center-right] 1fr [right];
  grid-template-rows: [top] auto [rest] 1fr [bottom];
  grid-template-areas: 
    "header header header"
    ". content .";
  height: 100%;
  width:100%;
  overflow:hidden;
  background-color: var(--main-bg-color);
  color: var(--font-color);
}

.App-header {
  font-size: calc(5vmin);
  font-weight: bold;
  border-bottom: solid grey 1px;
  padding-top:.2em;
  padding-bottom: .2em;
  grid-area: header;
  display:grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: "left title menu";
}
.title-text {
  text-align: center;
  grid-area: title;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "logo text .";
  white-space: nowrap;
  max-width: min(600px, 70vw);
}


.tools-holder {
  display:flex;
  grid-column:menu;
}
.show-statistics {
  cursor:pointer;
}
.show-about {
  cursor:pointer;
}
.App-body {
  display:flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding-top:1em;
  grid-area: content;
}

.button {
  border-radius: .5em;
  background-color: var(--button-color--default);
  padding:.5em;
  color: var(--button-font-color);
  border-style: solid;
  border-color: transparent;
}
.spacer {
  flex: 1 1;
}

.App-body > .Keyboard {
  margin-top: 1em;
}

.hook-text {
  display: none;
  margin-bottom: 1em;
  font-size: 2em;
}

.guesses-remaining {
  font-size: min(6vw, calc(min(800px, 100vw)*.06));
  margin-top: 5vh;
  user-select: none;
}

.answer-display {
  font-size: min(6vw, calc(min(800px, 100vw)*.06));
  text-transform: uppercase;
  margin-top:5vh;
}
.answer-display__noun, .answer-display__adjective {
  padding-left:.2em;
  padding-right:.2em;
  border-radius: 4px;
  background-color: var(--default-color);
  display: inline-block;
}

.art-holder {
  position:relative;
  width: 250px;
  height: 250px;
  max-width: 80vw;
  max-height: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.art-holder:before {
  content: "";
  position: absolute;
  background-image: url("/public/art_frame.png");
  width: 100%;
  height:100%;
  background-repeat:no-repeat; 
  background-position: center;
  background-size: contain;
}

.art {
  width: 70%;
  height: 70%;
}

.hidden {
}

.display-none {
  display:none;
}

.centerer {
  color:transparent;
  background-color: transparent;
}

.share-options-holder {
  margin-top:1em;
  grid-template-columns: 1fr 1fr;
  display: grid;
  flex: 0 0;
  grid-gap: 1em;
}

.share-options-holder__button {
  cursor: pointer;
  font-weight:bold;
  font-size:1em;
  white-space: nowrap;
}

.title-text .header-logo {
  grid-area: logo;
}

.header-logo {
  background-image: url("static/logo192.png");
  background-size: 1em;
  background-repeat: no-repeat;
  display:block;
  background-position-x: right;
  background-position-y: center;
/*  height:100%;*/
  margin-right: .5em;
  min-width:1em;
}

a {
  color: var(--link-color);
}

a:visited {
  color: var(--link-color--visited);
}

.install-app__button-holder {
  margin-top:1em;
  grid-template-columns: 1fr 1fr;
  display: grid;
  grid-gap :1em;
  flex: 0 0;
}

.install-app__button-holder__button {
  cursor: pointer;
  font-weight:bold;
  font-size:.8em;
  white-space: nowrap;
  background-color: var(--button-color--ok);
}

.install-app__button-holder__button--install {
  grid-column: 1;
}
.install-app__button-holder__button--noinstall {
  grid-column: 2;
  background-color: var(--button-color--danger);
}