.StatisticsScreen {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	pointer-events: none;
	text-align: center;
}

.coverall {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: black;
	transition: opacity .5s;
	pointer-events: default;
}

.StatisticsScreen.hidden .coverall {
	opacity: 0%;
	z-index: 0;
}
.StatisticsScreen:not(.hidden) .coverall {
	opacity: 60%;
	z-index: 20;
	pointer-events: auto;
}

.StatisticsModal {
	margin: 1.75rem auto;
	position: relative;
	color: var(--font-color);
	background-color: var(--main-bg-color);
	padding: 2em;
	max-width:30em;
	width: 80vw;
	transition: transform .5s;
	pointer-events: auto;
	display:flex;
	flex-direction:column;
}

.StatisticsScreen.hidden .StatisticsModal {
	-webkit-transform: translateY(-200vh);
          transform: translateY(-200vh);
	z-index: 0;

}

.StatisticsScreen:not(.hidden) .StatisticsModal {
	-webkit-transform: translateY(0);
          transform: translateY(0);
	z-index: 21;

}

.StatisticsModal__header {
	font-size:2em;
	display:grid;
	grid-template-columns: 1fr auto 1fr;
	white-space: nowrap;
	margin-bottom:.5em;
}


.StatisticsModal__result {
	font-size:2em;
}

.StatisticsModal__statistics-bar-holder {
	margin-bottom:1em;
}

.StatisticsModal__statistics-bar-holder__header {
	display:flex;
}
.StatisticsModal__stat-holder {
	display: flex;
	flex-direction: row;
	flex: 1 1;
}

.StatisticsModal__stat {
	display: flex;
	flex-direction: column;
	flex: 1 1;
	align-items: center;
}

.StatisticsModal__stat__holder {
	display: inline-flex;
	flex-direction: column;
}

.statsbar {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.statsbar__statname {
	flex: 0 0;
	margin-right:1em;
}
.statsbar__bar {
	flex: 1 1;
}

.progress-bar { 
  height: 20px;
  position: relative;
  padding: 10px;
}

.progress-bar > .bar {
	display: block;
  height: 100%;
  background-color: var(--progress-color);
  position: relative;
  overflow: hidden;
  text-align: right;
}
.progress-bar > .bar.active {
  background-color: var(--correct-color);
}

.progress-bar > .bar > .text {
	padding-right:1em;
}
.StatisticsModal__close {
	position:absolute;
	right:2em;
	top: 0;
	cursor: pointer;
	user-select: none;
}
.StatisticsModal__close::before {
	content: "x";
	position: absolute;
	color: var(--disabled-color);
	font-size:2em;
	font-weight:bold;
}

.StatisticsModal__footer {
	margin-top:1em;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto;
	display: grid;
	grid-gap :1em;
	flex: 0 0;
}

.share-button {
	cursor: pointer;
	font-weight:bold;
	font-size:.8em;
	white-space: nowrap;
  background-color: var(--button-color--ok);
}
.share-button--game {
	grid-column: 1;
}
.share-button--statistics {
	grid-column: 1;
	grid-row: 2;
}
.StatisticsModal__screenshot-div {
	background-color: var(--main-bg-color);
	padding-top:1em;
	padding-bottom:1em;
	overflow: visible;
}